import { Link } from "gatsby";
import React from "react";

import { Tooltip } from "../../components/tooltip";
import { Copy } from "../../components/copy";

import arrowRightImg from "../../images/icons/arrow-black.svg"
import sintelThumbnail from "../../images/products/sample/sintel_thumbnail.png"
import bigBuckBunnyThumbnail from "../../images/products/sample/big_buck_bunny_thumbnail.png"
import tearsOfSteelThumbnail from "../../images/products/sample/tears_of_steel_thumbnail.png"
import infoGreyIcon from "../../images/icons/info-grey.png"
import greenMagnetIcon from "../../images/icons/green-magnet.png"
import downloadIcon from "../../images/icons/download.png"

const sampleTorrent = () => {
    return (
        <div className="w-100" style={{ backgroundColor: "#DCDCDC" }}>
            <div className="container d-flex flex-column align-items-center pb-5">
                <div className="container d-flex flex-column align-items-center py-4 py-xl-3">
                    <span className="text-size-60-responsive font-weight-bold text-center">
                        Licensed Sample Torrents
                    </span>
                    <div className="d-flex text-center text-size-24-responsive">
                        <span className="pr-2">Try out these torrents on </span>
                        <Link className="d-flex align-items-center text-decoration-none" style={{ color: "#52A911" }} to="https://lite.utorrent.com">
                            <span className="pr-1 pr-xl-2">µTorrent Lite</span>
                            <img className="img-fluid sample-torrent-right-arrow" src={arrowRightImg} style={{ filter: "invert(53%) sepia(72%) saturate(3655%) hue-rotate(62deg) brightness(94%) contrast(87%)" }} />
                        </Link>
                    </div>
                </div>

                <div className="d-flex flex-wrap my-2 my-xl-3 w-100 rounded-xxl" style={{ backgroundColor: "white" }}>
                    <div className="col-12 col-xl-3 px-3 px-xl-4 py-3 py-xl-4 ">
                        <img className="img-fluid w-100 rounded-xxl" src={sintelThumbnail} />
                    </div>
                    <div className="d-flex flex-column col-12 col-xl-9">
                        <div className="d-flex d-xl-none mb-1">
                            <span className="text-size-32-responsive font-weight-bold">Sintel</span>
                        </div>

                        <div className="d-flex mb-2 mb-xl-none justify-content-xl-end">
                            <Tooltip
                                tooltipText={
                                    <span className="text-size-12">
                                        Used pursuant to the Creative Commons Attribution 3.0 license
                                        © copyright Blender Foundation | <a style={{ color: "#52A911" }} href="http://www.sintel.org">http://www.sintel.org</a>
                                    </span>}
                            >
                                <div className="d-flex align-items-center text-size-14 px-0 px-xl-2 py-0 py-xl-2">
                                    <img className="px-1" src={infoGreyIcon} style={{ width: "20px" }} />{` License`}
                                </div>
                            </Tooltip>
                        </div>

                        <div className="d-none d-xl-flex mb-3">
                            <span className="text-size-32-responsive font-weight-bold">Sintel</span>
                        </div>

                        <div className="d-flex flex-column flex-xl-row pb-3 pb-xl-0">
                            <div className="d-flex rounded-xl flex-column col py-2 px-3" style={{ border: "1px solid #00000033" }}>
                                <span className="text-size-14 py-1 font-weight-bold">Copy Magnet Link</span>
                                <div className="d-flex align-items-center py-1 px-2 mb-1 rounded-lg" style={{ backgroundColor: "#F1F1F2" }}>
                                    <div className="d-flex align-items-center flex-grow-1">
                                        <img className="mr-2" src={greenMagnetIcon} style={{ width: "11px", height: "12.5px" }} />
                                        {/* TODO: UPDATE VALUE */}
                                        <input
                                            className="flex-grow-1 text-size-14 border-none p-0 mr-1"
                                            readOnly
                                            style={{ color: "#52A911", textOverflow: "ellipsis", background: "none" }}
                                            value="magnet:?xt=urn:btih:5318660c4b1ae6cce06e11a5a630f51dd67c2bd1&dn=Sintel&tr=udp%3A%2F%2Ftracker.leechers-paradise.org%3A6969&tr=udp%3A%2F%2Ftracker.coppersurfer.tk%3A6969&tr=udp%3A%2F%2Ftracker.opentrackr.org%3A1337&tr=udp%3A%2F%2Fexplodie.org%3A6969&tr=udp%3A%2F%2Ftracker.empire-js.us%3A1337&tr=wss%3A%2F%2Ftracker.btorrent.xyz&tr=wss%3A%2F%2Ftracker.openwebtorrent.com&ws=https%3A%2F%2Fd2rkvwn9o31ezi.cloudfront.net%2F&xs=https%3A%2F%2Fd2rkvwn9o31ezi.cloudfront.net%2FSintel.torrent"
                                        />
                                    </div>
                                    <div>
                                        {/* TODO: UPDATE CLIPBOARD */}
                                        <Copy clipboard={"magnet:?xt=urn:btih:5318660c4b1ae6cce06e11a5a630f51dd67c2bd1&dn=Sintel&tr=udp%3A%2F%2Ftracker.leechers-paradise.org%3A6969&tr=udp%3A%2F%2Ftracker.coppersurfer.tk%3A6969&tr=udp%3A%2F%2Ftracker.opentrackr.org%3A1337&tr=udp%3A%2F%2Fexplodie.org%3A6969&tr=udp%3A%2F%2Ftracker.empire-js.us%3A1337&tr=wss%3A%2F%2Ftracker.btorrent.xyz&tr=wss%3A%2F%2Ftracker.openwebtorrent.com&ws=https%3A%2F%2Fd2rkvwn9o31ezi.cloudfront.net%2F&xs=https%3A%2F%2Fd2rkvwn9o31ezi.cloudfront.net%2FSintel.torrent"} />
                                    </div>
                                </div>
                            </div>

                            <div className="d-flex px-3 align-items-center justify-content-center">
                                <span className="text-size-14">
                                    or
                                </span>
                            </div>

                            <div className="d-flex rounded-xl flex-column col py-2 px-3" style={{ border: "1px solid #00000033" }}>
                                <span className="text-size-14 py-1 font-weight-bold">Download .torrent file</span>
                                <div className="d-flex align-items-center py-1 px-2 mb-1 rounded-lg" style={{ backgroundColor: "#F1F1F2" }}>
                                    <div className="d-flex align-items-center flex-grow-1">
                                        {/* TODO: UPDATE DOWNLOAD LINK HERE */}
                                        <a href="https://d2rkvwn9o31ezi.cloudfront.net/Sintel.torrent" className="pointer" download >
                                            <span className="text-size-14" style={{ color: "#52A911" }}>
                                                Sintel.torrent
                                            </span>
                                        </a>
                                    </div>
                                    <div>
                                        {/* TODO: UPDATE DOWNLOAD LINK HERE */}
                                        <a href="https://d2rkvwn9o31ezi.cloudfront.net/Sintel.torrent" className="pointer" download >
                                            <img src={downloadIcon} />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="d-flex flex-wrap my-2 my-xl-3 w-100 rounded-xxl" style={{ backgroundColor: "white" }}>
                    <div className="col-12 col-xl-3 px-3 px-xl-4 py-3 py-xl-4 ">
                        <img className="img-fluid w-100 rounded-xxl" src={tearsOfSteelThumbnail} />
                    </div>
                    <div className="d-flex flex-column col-12 col-xl-9">
                        <div className="d-flex d-xl-none mb-1">
                            <span className="text-size-32-responsive font-weight-bold">Tears of Steel</span>
                        </div>

                        <div className="d-flex mb-2 mb-xl-none justify-content-xl-end">
                            <Tooltip
                                tooltipText={
                                    <span className="text-size-12">
                                        Used pursuant to the Creative Commons Attribution 3.0 license.
                                        (CC) Blender Foundation |
                                        <a style={{ color: "#52A911" }} href="http://mango.blender.org">http://mango.blender.org</a>
                                    </span>}
                            >
                                <div className="d-flex align-items-center text-size-14 px-0 px-xl-2 py-0 py-xl-2">
                                    <img className="px-1" src={infoGreyIcon} style={{ width: "20px" }} />{` License`}
                                </div>
                            </Tooltip>
                        </div>

                        <div className="d-none d-xl-flex mb-3">
                            <span className="text-size-32-responsive font-weight-bold">Tears of Steel</span>
                        </div>

                        <div className="d-flex flex-column flex-xl-row pb-3 pb-xl-0">
                            <div className="d-flex rounded-xl flex-column col py-2 px-3" style={{ border: "1px solid #00000033" }}>
                                <span className="text-size-14 py-1 font-weight-bold">Copy Magnet Link</span>
                                <div className="d-flex align-items-center py-1 px-2 mb-1 rounded-lg" style={{ backgroundColor: "#F1F1F2" }}>
                                    <div className="d-flex align-items-center flex-grow-1">
                                        <img className="mr-2" src={greenMagnetIcon} style={{ width: "11px", height: "12.5px" }} />
                                        {/* TODO: UPDATE VALUE */}
                                        <input
                                            className="flex-grow-1 text-size-14 border-none p-0 mr-1"
                                            readOnly
                                            style={{ color: "#52A911", textOverflow: "ellipsis", background: "none" }}
                                            value="magnet:?xt=urn:btih:8784b99e852522ebca424f961d99f3664dc5e62c&dn=Tears%20of%20Steel&tr=udp%3A%2F%2Ftracker.leechers-paradise.org%3A6969&tr=udp%3A%2F%2Ftracker.coppersurfer.tk%3A6969&tr=udp%3A%2F%2Ftracker.opentrackr.org%3A1337&tr=udp%3A%2F%2Fexplodie.org%3A6969&tr=udp%3A%2F%2Ftracker.empire-js.us%3A1337&tr=wss%3A%2F%2Ftracker.btorrent.xyz&tr=wss%3A%2F%2Ftracker.openwebtorrent.com&ws=https%3A%2F%2Fd2rkvwn9o31ezi.cloudfront.net%2F&xs=https%3A%2F%2Fd2rkvwn9o31ezi.cloudfront.net%2FTears%20of%20Steel.torrent"
                                        />
                                    </div>
                                    <div>
                                        {/* TODO: UPDATE CLIPBOARD */}
                                        <Copy clipboard={"magnet:?xt=urn:btih:8784b99e852522ebca424f961d99f3664dc5e62c&dn=Tears%20of%20Steel&tr=udp%3A%2F%2Ftracker.leechers-paradise.org%3A6969&tr=udp%3A%2F%2Ftracker.coppersurfer.tk%3A6969&tr=udp%3A%2F%2Ftracker.opentrackr.org%3A1337&tr=udp%3A%2F%2Fexplodie.org%3A6969&tr=udp%3A%2F%2Ftracker.empire-js.us%3A1337&tr=wss%3A%2F%2Ftracker.btorrent.xyz&tr=wss%3A%2F%2Ftracker.openwebtorrent.com&ws=https%3A%2F%2Fd2rkvwn9o31ezi.cloudfront.net%2F&xs=https%3A%2F%2Fd2rkvwn9o31ezi.cloudfront.net%2FTears%20of%20Steel.torrent"} />
                                    </div>
                                </div>
                            </div>

                            <div className="d-flex px-3 align-items-center justify-content-center">
                                <span className="text-size-14">
                                    or
                                </span>
                            </div>

                            <div className="d-flex rounded-xl flex-column col py-2 px-3" style={{ border: "1px solid #00000033" }}>
                                <span className="text-size-14 py-1 font-weight-bold">Download .torrent file</span>
                                <div className="d-flex align-items-center py-1 px-2 mb-1 rounded-lg" style={{ backgroundColor: "#F1F1F2" }}>
                                    <div className="d-flex align-items-center flex-grow-1">
                                        {/* TODO: UPDATE DOWNLOAD LINK HERE */}
                                        <a href="https://d2rkvwn9o31ezi.cloudfront.net/Tears%20of%20Steel.torrent" className="pointer" download >
                                            <span className="text-size-14" style={{ color: "#52A911" }}>
                                                Tears_of_Steel.torrent
                                            </span>
                                        </a>
                                    </div>
                                    <div>
                                        {/* TODO: UPDATE DOWNLOAD LINK HERE */}
                                        <a href="https://d2rkvwn9o31ezi.cloudfront.net/Tears%20of%20Steel.torrent" className="pointer" download >
                                            <img src={downloadIcon} />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="d-flex flex-wrap my-2 my-xl-3 w-100 rounded-xxl" style={{ backgroundColor: "white" }}>
                    <div className="col-12 col-xl-3 px-3 px-xl-4 py-3 py-xl-4 ">
                        <img className="img-fluid w-100 rounded-xxl" src={bigBuckBunnyThumbnail} />
                    </div>
                    <div className="d-flex flex-column col-12 col-xl-9">
                        <div className="d-flex d-xl-none mb-1">
                            <span className="text-size-32-responsive font-weight-bold">Big Buck Bunny</span>
                        </div>

                        <div className="d-flex mb-2 mb-xl-none justify-content-xl-end">
                            <Tooltip
                                tooltipText={
                                    <span className="text-size-12">
                                        Used pursuant to the Creative Commons Attribution 3.0 license.
                                        © copyright 2008, Blender Foundation | <a style={{ color: "#52A911" }} href="http://www.bigbuckbunny.org">http://www.bigbuckbunny.org</a>
                                    </span>}
                            >
                                <div className="d-flex align-items-center text-size-14 px-0 px-xl-2 py-0 py-xl-2">
                                    <img className="px-1" src={infoGreyIcon} style={{ width: "20px" }} />{` License`}
                                </div>
                            </Tooltip>
                        </div>

                        <div className="d-none d-xl-flex mb-3">
                            <span className="text-size-32-responsive font-weight-bold">Big Buck Bunny</span>
                        </div>

                        <div className="d-flex flex-column flex-xl-row pb-3 pb-xl-0">
                            <div className="d-flex rounded-xl flex-column col py-2 px-3" style={{ border: "1px solid #00000033" }}>
                                <span className="text-size-14 py-1 font-weight-bold">Copy Magnet Link</span>
                                <div className="d-flex align-items-center py-1 px-2 mb-1 rounded-lg" style={{ backgroundColor: "#F1F1F2" }}>
                                    <div className="d-flex align-items-center flex-grow-1">
                                        <img className="mr-2" src={greenMagnetIcon} style={{ width: "11px", height: "12.5px" }} />
                                        {/* TODO: UPDATE VALUE */}
                                        <input
                                            className="flex-grow-1 text-size-14 border-none p-0 mr-1"
                                            readOnly
                                            style={{ color: "#52A911", textOverflow: "ellipsis", background: "none" }}
                                            value="magnet:?xt=urn:btih:6bd15b7938f3d16e7511eabe54eb81fb19e491f4&dn=Big%20Buck%20Bunny&tr=udp%3A%2F%2Ftracker.leechers-paradise.org%3A6969&tr=udp%3A%2F%2Ftracker.coppersurfer.tk%3A6969&tr=udp%3A%2F%2Ftracker.opentrackr.org%3A1337&tr=udp%3A%2F%2Fexplodie.org%3A6969&tr=udp%3A%2F%2Ftracker.empire-js.us%3A1337&tr=wss%3A%2F%2Ftracker.btorrent.xyz&tr=wss%3A%2F%2Ftracker.openwebtorrent.com&ws=https%3A%2F%2Fd2rkvwn9o31ezi.cloudfront.net%2F&xs=https%3A%2F%2Fd2rkvwn9o31ezi.cloudfront.net%2FBig%20Buck%20Bunny.torrent"
                                        />
                                    </div>
                                    <div>
                                        {/* TODO: UPDATE CLIPBOARD */}
                                        <Copy clipboard={"magnet:?xt=urn:btih:6bd15b7938f3d16e7511eabe54eb81fb19e491f4&dn=Big%20Buck%20Bunny&tr=udp%3A%2F%2Ftracker.leechers-paradise.org%3A6969&tr=udp%3A%2F%2Ftracker.coppersurfer.tk%3A6969&tr=udp%3A%2F%2Ftracker.opentrackr.org%3A1337&tr=udp%3A%2F%2Fexplodie.org%3A6969&tr=udp%3A%2F%2Ftracker.empire-js.us%3A1337&tr=wss%3A%2F%2Ftracker.btorrent.xyz&tr=wss%3A%2F%2Ftracker.openwebtorrent.com&ws=https%3A%2F%2Fd2rkvwn9o31ezi.cloudfront.net%2F&xs=https%3A%2F%2Fd2rkvwn9o31ezi.cloudfront.net%2FBig%20Buck%20Bunny.torrent"} />
                                    </div>
                                </div>
                            </div>

                            <div className="d-flex px-3 align-items-center justify-content-center">
                                <span className="text-size-14">
                                    or
                                </span>
                            </div>

                            <div className="d-flex rounded-xl flex-column col py-2 px-3" style={{ border: "1px solid #00000033" }}>
                                <span className="text-size-14 py-1 font-weight-bold">Download .torrent file</span>
                                <div className="d-flex align-items-center py-1 px-2 mb-1 rounded-lg" style={{ backgroundColor: "#F1F1F2" }}>
                                    <div className="d-flex align-items-center flex-grow-1">
                                        {/* TODO: UPDATE DOWNLOAD LINK HERE */}
                                        <a href="https://d2rkvwn9o31ezi.cloudfront.net/Big%20Buck%20Bunny.torrent" className="pointer" download >
                                            <span className="text-size-14" style={{ color: "#52A911" }}>
                                                Big_Buck_Bunny.torrent
                                            </span>
                                        </a>
                                    </div>
                                    <div>
                                        {/* TODO: UPDATE DOWNLOAD LINK HERE */}
                                        <a href="https://d2rkvwn9o31ezi.cloudfront.net/Big%20Buck%20Bunny.torrent" className="pointer" download >
                                            <img src={downloadIcon} />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default sampleTorrent
