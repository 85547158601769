import React from "react";

const Tooltip = ({ children, tooltipText, className, ...rest }) => {
    return (
        <div className="d-flex flex-column custom-tooltip align-items-end">
            {children}
            <span className={`tooltiptext p-2 ${className ? className : ""}`} {...rest}>{tooltipText}</span>
        </div>
    )
}

export { Tooltip }