import React, { useState } from "react"
import CopyIcon from "../images/icons/copy.png"
import CheckIcon from "../images/components/featuresList/check.svg"

const Copy = ({ timeout, clipboard }) => {
    const [popup, setPopup] = useState(false)

    let timeoutFn

    const handleClick = () => {
        navigator.clipboard.writeText(clipboard ? clipboard : "")

        clearTimeout(timeoutFn)
        setPopup(true)

        const time = timeout ? parseInt(timeout) : 1650
        timeoutFn = setTimeout(disablePopup, time)
    }

    const disablePopup = () => {
        setPopup(false)
    }

    return (
        <div
            style={{
                position: "relative",
            }}
            className="d-flex flex-column align-items-center"
        >
            {popup &&
                <div
                    className="display-flex align-items-center p-1 m-2"
                    style={{
                        textAlign: "center",
                        position: "absolute",
                        bottom: "100%",
                        backgroundColor: "#F1F1F2",
                        borderRadius: "10px",
                        width: "120px"
                    }}>
                    <img className="pr-1" style={{ width: "18px" }} src={CheckIcon}></img>
                    <span className="text-size-12">Magnet Copied!</span>

                </div>}
            <img className="img-fluid pointer" src={CopyIcon} onClick={handleClick} />
        </div>
    )
}

export { Copy }